import React from "react";
import { HalfTextHalfImg2 } from "../CommonComponents/HalfTextHalfImg2";
import { CommonHeading } from "../CommonComponents/CommonHeading";
import { HalfImgHalfText } from "../CommonComponents/HalfImgHalfText";
import { Off_40_percent } from "../CommonComponents/Off40%Common";
import { SoftwareWeUse } from "../CommonComponents/SoftwareWeUse";

export const QuantitiesTakeOff = () => {
  const text = (
    <div>
      <CommonHeading heading="Quantity Takeoff Services" />
      <p className=" paragrapg">
        {" "}
        Our team provides high <b>Quality Takeoff Services</b> to many
        construction companies. Our experienced team, equipped with the latest
        technologies and tools, provides fast and accurate quantity takeoff
        numbers for labor and material. Even on short notice, we can provide
        many Quantity takeoff services within 24 hours. With 10 years of
        experience in the US construction industry, we have successfully
        provided integrated quantity takeoff services and solutions for a
        variety of projects, including remodeling and renovations in different
        parts of the project. Contact us via
        <a
          className=" text-decoration-none"
          href="mailto:Davidson@bidclerkestimating.com"
        >
          {" "}
          <span style={{ color: "#028EC9" }}>
            {" "}
             Davidson@bidclerkestimating.com{" "}
          </span>
        </a>
         or{" "}
        <a className=" text-decoration-none" href="tel:+1 917 300 1079">
          <span style={{ color: "#028EC9" }}>  +1 917 300 1079.</span>
        </a>
      </p>
    </div>
  );

  const text2 = (
    <div>
      <CommonHeading heading="Quantity Takeoff Service Area" />
      <p className=" paragrapg">
        We help clients all over the United States with our Estimating services.
        Whether you're in any part of the country or bidding on any project,
        we've got you covered. We specialize in figuring out how much material
        and labor you'll need for your construction project. Our team will go
        through the blueprints and list all the materials you need in a format
        you can easily use, like Microsoft Excel or Adobe. We're fast and
        accurate, making sure you have everything you need to build. Plus, we
        organize the materials by location, making it simple for your team to
        know where everything goes.
      </p>
    </div>
  );
  return (
    <div
      className="animate__animated animate__zoomIn"
      style={{ marginTop: "90px" }}
    >
      <div className=" container-md">
        <HalfTextHalfImg2
          text={text}
          src={require("../../Images/QTAKEOFFHTHTI.png")}
        />
      </div>
      <div className="TakeOffBlueContainer">
        <div className=" container-md ">
          <p className=" fw-semibold fs-4 text-capitalize text-center">
            what categories of quantity takeoff do we provide?
          </p>
          <p className=" paragrapg text-center">
            BidClerk Estimating offers top-notch quantity takeoff services
            tailored to meet the needs of general contractors, subcontractors,
            engineering firms, architects, and developers. Our team of expert
            estimators utilizes advanced market takeoff software and tools to
            provide precise and timely quantity takeoff and material takeoff
            estimates. Whether you require quantity takeoffs within 24 hours or
            comprehensive project estimations, our skilled professionals are
            here to assist you every step of the way.
          </p>
          <div className=" row w-100 m-0 justify-content-center">
            <div className=" col-md-4 col-sm-6 co-12 d-flex justify-content-center align-items-center">
              {" "}
              <div>
                {" "}
                <ul className=" paragrapg">
                  <li>SiteWork Takeoff</li>
                  <li>Masonry Takeoff</li>
                  <li>Concrete Takeoff</li>
                  <li>Drywall Takeoff</li>
                </ul>
              </div>{" "}
            </div>
            <div className=" col-md-4 col-sm-6 co-12 d-flex justify-content-center align-items-center">
              {" "}
              <div>
                {" "}
                <ul className=" paragrapg">
                  <li>Painting Takeoff</li>
                  <li>Insulation Takeoff</li>
                  <li>Roofing Takeoff</li>
                  <li>Lumber Takeoff</li>
                </ul>
              </div>{" "}
            </div>
            <div className=" col-md-4 col-sm-6 co-12 d-flex justify-content-center align-items-center">
              {" "}
              <div>
                {" "}
                <ul className=" paragrapg">
                  <li>Millwork Takeoff</li>
                  <li>Cabinet Takeoff</li>
                  <li>Mechanical Takeoff</li>
                  <li>HVAC Takeoff</li>
                </ul>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className=" container-md">
        <CommonHeading heading="Why bidClerk Estimating Quantity TakeOff Services Better Than The Best?" />
        <p className=" paragrapg">
          <span style={{ color: "#028EC9", fontWeight: "500" }}>
            BidClerk Estimating
          </span>{" "}
          is a leading provider of Quantity TakeOff Services. We have been
          providing precise Estimates and takeoff services to our customers for
          around 3 decades and are committed to providing the highest quality of
          customer service. <br /> We Provide quotes for all your quantity
          takeoff projects within 5 minutes and 24/7 email support. You can also
          get in touch with us through our website or through social media
          platforms like Facebook, Instagram, and LinkedIn. <br /> Trust{" "}
          <span style={{ color: "#028EC9", fontWeight: "500" }}>
            BidClerk Estimating 
          </span>
          &nbsp; for quality products at great prices, with instant revisions included.
          With 10 years of experience serving clients across industries like
          real estate, construction, and retail,{" "}
          <span style={{ color: "#028EC9", fontWeight: "500" }}>
            BidClerk Estimating 
          </span>{" "}
           stands out for its customer-focused approach.
        </p>

        <div>
          <div className=" row m-0 w-100 ">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className=" col-lg-6  col-12 p-0"
            >
              <Off_40_percent />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className=" col-lg-6  col-12 p-0"
            >
              {" "}
              <div className=" p-4">
                <CommonHeading heading="Who Have We Helped with Our Quantity Takeoff Services?" />
                <p className="paragraph">
                  <span className=" fw-semibold mt-2">
                    Discover the array of industries we've served through our
                    Quantity Takeoff Services.
                  </span>
                  <br />
                  <span className=" row w-100 mt-2">
                    <span className="col-md-6 col-12">
                      <ul className=" text-uppercase">
                        <li>General Contractors</li>
                        <li>Subcontractors</li>
                        <li>Projects managers</li>
                        <li>investors</li>
                        <li>Architects</li>
                      </ul>
                    </span>
                    <span className="col-md-6 col-12">
                      <ul className=" text-uppercase">
                        <li>home builders</li>
                        <li>owners</li>
                        <li>Developers</li>
                        <li>vendors</li>
                      </ul>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <HalfTextHalfImg2
          text={text2}
          src={require("../../Images/QTOGGHTHTI2.png")}
        />

        <SoftwareWeUse />
      </div>
    </div>
  );
};
