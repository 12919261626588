import React from "react";
import { HalfTextHalfImg2 } from "../CommonComponents/HalfTextHalfImg2";
import { CommonHeading } from "../CommonComponents/CommonHeading";
import { HalfImgHalfText } from "../CommonComponents/HalfImgHalfText";
import { Off_40_percent } from "../CommonComponents/Off40%Common";
import { SoftwareWeUse } from "../CommonComponents/SoftwareWeUse";

export const MaterialTakeOff = () => {
  const text = (
    <div>
      <CommonHeading heading="Material Takeoff Services" />
      <p className=" paragrapg">
        {" "}
        Explore our <b>Material Takeoff Services</b> for accurate project material
        calculations. Our skilled team ensures precise estimates, simplifying
        your construction process. Trust us for reliable results and streamlined
        project management. we can provide many Material takeoff services within
        24 hours. With 10 years of experience in the US construction industry,
        we have successfully provided integrated Material takeoff services and
        solutions for a variety of projects, including remodeling and
        renovations in different parts of the project. Contact us 
        <a
          className=" text-decoration-none"
          href="mailto:Davidson@bidclerkestimating.com"
        >
          {" "}
          <span style={{ color: "#028EC9" }}>
            {" "}
            via Davidson@bidclerkestimating.com{" "}
          </span>
        </a>
         or{" "}
        <a className=" text-decoration-none" href="tel:+1 917 300 1079">
          <span style={{ color: "#028EC9" }}>  +1 917 300 1079.</span>
        </a>
      </p>
    </div>
  );

  const text2 = (
    <div>
      <CommonHeading heading="take-off formate" />
      <p className=" paragrapg">
        Our work would be in editable excel sheet – if required, we can use your
        template or can put your company details on the estimate/proposal. For
        to do quantities takeoff – we use software Planswift, BlueBeam, On
        Screen Takeoff (OST). <br />
        Mostly we use Planswift. We will be very happy to provide Software files
        as well as Markups/color drawings (if required).
      </p>
    </div>
  );
  return (
    <div className="animate__animated animate__zoomIn" style={{ marginTop: "90px" }}>
      <div className=" container-md">
        <HalfTextHalfImg2
          text={text}
          src={require("../../Images/MTHTHI.png")}
        />
      </div>
      <div className="TakeOffBlueContainer">
        <div className=" container-md ">
          <h5 className=" text-uppercase text-center">
            How Can Our Professionals Assist with Material Takeoff?
          </h5>
          <p className=" paragrapg text-center">
            Our professionals can deliver the best material takeoff Services
            after a brief analysis of your construction project where the actual
            construction will take place. Our experts can quantify project
            materials for your construction more efficiently and with precision.
            We offer quality Material takeoff services for the following
            categories:
          </p>
          <div className=" row w-100 m-0 justify-content-center">
            <div className=" col-md-4 col-sm-6 co-12 d-flex justify-content-center align-items-center">
              {" "}
              <div>
                {" "}
                <ul className=" paragrapg">
                  <li>Masonry</li>
                  <li>General Requirement</li>
                  <li>SiteWorks</li>
                  <li>Concrete</li>
                </ul>
              </div>{" "}
            </div>
            <div className=" col-md-4 col-sm-6 co-12 d-flex justify-content-center align-items-center">
              {" "}
              <div>
                {" "}
                <ul className=" paragrapg">
                  <li>Wood and Plastics</li>
                  <li>Specialties</li>
                  <li>Equipment</li>
                  <li>Furnishings</li>
                </ul>
              </div>{" "}
            </div>
            <div className=" col-md-4 col-sm-6 co-12 d-flex justify-content-center align-items-center">
              {" "}
              <div>
                {" "}
                <ul className=" paragrapg">
                  <li>Metals</li>
                  <li>Thermal and Moisture Protection</li>
                  <li>Finishes</li>
                  <li>Doors and Windows</li>
                </ul>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className=" container-md">
        <CommonHeading heading="Why bidClerk Estimating Material TakeOff Services Better Than The Best?" />
        <p className=" paragrapg">
          At{" "}
          <span style={{ color: "#028EC9", fontWeight: "500" }}>
            BidClerk Estimating
          </span>
          , we excel in Material Takeoff Services. With nearly three decades of
          experience, we're dedicated to delivering accurate estimates and
          Material takeoff services, ensuring top-notch customer satisfaction.
          <br />
          We Provide quotes for all your Material takeoff projects within 5
          minutes and 24/7 email support. Our live chat and call support team is
          available at all times. You can also get in touch with us through our
          website or through social media platforms like Facebook, Twitter,
          Instagram, and LinkedIn.
          <br /> Trust{" "}
          <span style={{ color: "#028EC9", fontWeight: "500" }}>
            BidClerk Estimating
          </span>{" "}
          for quality products at great prices, with instant revisions included.
          With 10 years of experience serving clients across industries like
          real estate, construction, and retail,{" "}
          <span style={{ color: "#028EC9", fontWeight: "500" }}>
            BidClerk Estimating
          </span>{" "}
          stands out for its customer-focused approach.
        </p>

        <div>
          <div className=" row m-0 w-100 ">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className=" col-lg-5  col-12 p-0"
            >
              <Off_40_percent />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className=" col-lg-7  col-12 p-0"
            >
              {" "}
              <div className=" p-4">
                <CommonHeading heading="Who Have We Helped with Our Material Takeoff Services?" />
                <p className="paragraph">
                  <span className=" fw-semibold mt-2">
                    Understanding Your Needs: Tailored Construction Cost
                    Estimating Services:
                  </span>
                  <br />
                  <span className=" row w-100 mt-2">
                    <span className="col-md-6 col-12">
                      <ul className=" text-uppercase">
                        <li>General Contractors</li>
                        <li>Subcontractors</li>
                        <li>Projects managers</li>
                        <li>investors</li>
                        <li>Architects</li>
                      </ul>
                    </span>
                    <span className="col-md-6 col-12">
                      <ul className=" text-uppercase">
                        <li>home builders</li>
                        <li>owners</li>
                        <li>Developers</li>
                        <li>vendors</li>
                      </ul>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <HalfTextHalfImg2 text={text2} src={require('../../Images/MTOHTHI2.png')} />

        <SoftwareWeUse />
      </div>
    </div>
  );
};
