import React from "react";

export const PageNotFound = () => {
  return (
    <div
      style={{ height: "400px" }}
      className=" text-center w-100  d-flex justify-content-center align-items-center"
    >
      {" "}
      <span className=" fs-1 fw-semibold"> 404-Page Not Found 😞</span>
    </div>
  );
};
